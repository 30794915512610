import type { CloseButtonProps } from "@chakra-ui/react";
// eslint-disable-next-line no-restricted-imports
import { CloseButton as ChakraCloseButton } from "@chakra-ui/react";
import { useTrack } from "../use-track";
import type { TrackProps } from "../types";

export function CloseButton(props: CloseButtonProps & TrackProps): JSX.Element {
  const { eventName, eventProperties, ...closeButtonProps } = props;
  const track = useTrack();

  return (
    <ChakraCloseButton
      {...closeButtonProps}
      onClick={(e): void => {
        track(eventName, eventProperties);
        props.onClick?.(e);
      }}
    />
  );
}
